import Cookie from '@/service/cookie';
import axios from 'axios';
import store from '@/store';
import api from '../plugins/api'

export default {
    async redirectIfNotAuthenticated(to, from, next) {
        const token = Cookie.getToken();

        if (!token) {
            next({ name: 'login' });
        }
        // Invoco meu serviço para recuperar as informações do usuário logado.
        await api.get('v1/me', { headers: {'Authorization': token }}).then((response) => {
            //Verifico se já existe o usuario comitado no meu vuex
            if (!store?.state?.user?.id) {
                //Caso não exista ele commita as infos do usuário logado na store que criei user/STORE_USER do vuex
                store.commit('user/STORE_USER', response.data.data);
            }
        }).catch(() => {
            // Caso o token nõ esteja correto ou não esteja sendo passado no Header/Autoriation
            // Deleta o token errado dos cookies
            Cookie.deleteToken();
            //Redireciona para página de login
            next({ name: 'login' });
        });

        next();
    },

    redirectIfAuthenticated(to, from, next) {
        const token = Cookie.getToken();
        let n;

        if (token) {
            n = { name: 'index' };
        }

        next(n);
    },
};
