<template>
    <div>
        <div v-if="response.message" :class="`rounded-sm bg-${response.color}-100 p-4 mb-4`">
            <h3 :class="`text-sm leading-5 font-medium text-${response.color}-800`">
                {{ response.message }}
            </h3>
        </div>
        <!-- /.login-logo -->
        <div class="card">
            <div class="card-body login-card-body">
                <p class="login-box-msg">Inicie sua sessão no sistema Linear</p>

                <Form @submit="login" :validation-schema="schema" v-slot="{errors}" ref="loginForm">
                    <span class="p-error">{{ errors.email }}</span>
                    <div class="input-group mb-3">
                        <Field v-model="email" class="form-control" name="email" type="email" :class="{'p-invalid':errors.name}" />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <span class="p-error">{{ errors.password }}</span>
                    <div class="input-group mb-3">
                        <Field v-model="password" class="form-control" name="password" type="password" :class="{'p-invalid':errors.password}" />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-key"></span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- /.col -->
                        <div class="col-12">
                            <button :disabled="spinner.login" type="submit" class="btn btn-primary btn-block">ENTRAR <img
                                    v-if="spinner.login" height="20" width="20" src="@/assets/img/spinner.svg" alt=""
                                    class="w-5 h-5 mr-2">
                            </button>
                        </div>
                        <!-- /.col -->
                    </div>
                </Form>
                <p class="mt-3 mb-1">
                    <RouterLink :to="{ name: 'forgotPassword' }" class="text-sm font-light">
                        Esqueci minha senha
                    </RouterLink>
                </p>
            </div>
            <!-- /.login-card-body -->
        </div>
    </div>
</template>

<script>
import api from '@/plugins/api'
import * as yup from 'yup'
import { Form, Field, ErrorMessage } from 'vee-validate';
import messages from '@/utils/messages';
import Cookie from "@/service/cookie";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
    name: 'LayoutLogin',

    components: {
        Field,
        Form,
    },
    
    
    data() {
        const schema = yup.object({
            email: yup.string().email().required(),
            password: yup.string().required(),
        });
        return {
            schema,
            email: '',
            password: '',
            response: {
                color: '',
                message: '',
            },
            spinner: {
                login: false,
            },
        };
    },

    methods: {

        async login() {

            const validator = await this.$refs.loginForm.validate();

            if (!validator) { return; }

            const payload = {
                email: this.email,
                password: this.password,
            };

            this.resetResponse();

            this.spinner.login = true;

            api.post('/v1/login', payload).then(response => {
                const token = `${response.data.token_type} ${response.data.access_token}`;
                Cookie.setToken(token);

                this.$store.commit('user/STORE_USER', response.data.data)
                this.$router.push({ name: 'index' });

            }).catch((e) => {
                this.spinner.login = false;
                const errorCode = e?.response?.data?.error || 'ServerError';
                toast(messages[errorCode], { autoClose: 3000, });
            });

        },
        resetResponse() {
            this.response.color = '';
            this.response.message = '';
        },
    },
};
</script>
