<template>
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Categorias</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <RouterLink :to="{ name: 'index' }">Início</RouterLink>
                        </li>
                        <li class="breadcrumb-item active">Categorias</li>
                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">

        <!-- Default box -->
        <div class="card">
            <div class="card-header">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <RouterLink :to="{ name: 'addCategory' }" class="btn btn-primary btn-block">Adicionar nova
                        </RouterLink>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="text-center">
                                Nome da categoria
                            </th>
                            <th class="text-center">
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(category, index) in categories" :key="index" :value="category.id">
                            <td class="text-center">{{ category.name }}</td>
                            <td class="text-center">
                                <RouterLink :to="{ path: '/categorias/editar/' + category.id }" class="btn btn-default">Editar
                                    categoria</RouterLink>
                                &nbsp;
                                <button type="button" @click="deleteCategory(category.id)" class="btn btn-default">Excluir
                                    categoria</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
        <!-- /.card -->

    </section>
    <!-- /.content -->
</template>

<script>

import api from '@/plugins/api'
import Cookie from '@/service/cookie';
import Swal from 'sweetalert2';

export default {
    name: 'CategoryView',

    data() {
        return {
            category_id: '',
            categories: {},

        };
    },

    mounted() {
        this.getCategories();
    },

    methods: {
        getCategories() {
            const token = Cookie.getToken();
            api.get('v1/categories', { headers: { 'Authorization': token } }).then(response => {
                this.categories = response.data;
            });
        },

        deleteCategory(categoryId) {
            Swal.fire({
                title: 'Você quer excluir esse registro?',
                text: "Você não poderá reverter isso!",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Pensei melhor, não quero!',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, quero deletar!'
            }).then((result) => {
                if (result.isConfirmed) {
                    const token = Cookie.getToken();
                    api.delete(`v1/categories/${categoryId}`, { headers: { 'Authorization': token } }).then(response => {
                        Swal.fire(
                        'Registro excluído!',
                        'O registro selecionado foi excluído.',
                        'success'
                    ),
                    this.getCategories();
                });   
                }
            })
        }
    },
}
</script>

<style></style>