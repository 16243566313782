<template>
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Adicionar novo painel</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <RouterLink :to="{ name: 'index' }">Início</RouterLink>
                        </li>
                        <li class="breadcrumb-item active">Adicionar novo painel</li>
                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
            <Form @submit="add" :validation-schema="schema" v-slot="{ errors }" ref="addForm">
                <div class="row">
                    <div class="col-md-12">
                        <!-- Card de Informacoes gerais -->
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Informações gerais</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-3">
                                        <label for="exampleInputEmail1">Código do painel</label>
                                        <Field v-model="internal_code" class="form-control" name="internal_code"
                                            type="text" />
                                    </div>
                                    <div class="col-6">
                                        <span class="p-error">{{ errors.name }}</span>
                                        <label for="exampleInputEmail1">Nome do painel</label>
                                        <Field v-model="name" class="form-control" name="name" type="text"
                                            :class="{ 'p-invalid': errors.name }" />
                                    </div>
                                    <div class="col-3">
                                        <label for="exampleInputPassword1">Ativo para venda?</label>
                                        <select v-model="active_sale" name="active_sale" class="custom-select">
                                            <option value="1">Sim</option>
                                            <option value="0">Não</option>
                                        </select>
                                    </div>
                                </div>
                                <p></p>
                                <div class="row">
                                    <div class="col-4">
                                        <label for="exampleInputPassword1">Categoria</label>
                                        <select v-model="fk_id_categoria" name="fk_id_categoria" class="custom-select">
                                            <option v-for="(category, index) in categories" :key="index" :value="category.id">{{ category.name }}</option>
                                        </select>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <!-- Card de dados do site -->
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Dados do site</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6">
                                        <label for="exampleInputPassword1">Ativo no site?</label>
                                        <select v-model="active_site" name="active_site" class="custom-select">
                                            <option value="1">Sim</option>
                                            <option value="0">Não</option>
                                        </select>
                                    </div>
                                    <div class="col-6">
                                        <label for="exampleInputPassword1">Destaque no site?</label>
                                        <select v-model="detach" name="detach" class="custom-select">
                                            <option value="1">Sim</option>
                                            <option value="0">Não</option>
                                        </select>
                                    </div>
                                </div>
                                <p></p>
                                <div class="row">
                                    <div class="col-6">
                                        <label for="exampleInputEmail1">Título da página</label>
                                        <Field v-model="site_title" class="form-control" name="name" type="text" />
                                    </div>
                                    <div class="col-6">
                                        <label for="exampleInputEmail1">URL da página</label>
                                        <Field v-model="site_url" class="form-control" name="site_url" type="text" />
                                    </div>
                                </div>
                                <p></p>
                                <div class="row">
                                    <div class="col-6">
                                        <label for="exampleInputEmail1">Palavras chaves da página</label>
                                        <Field v-model="site_keyword" class="form-control" name="site_keyword"
                                            type="text" />
                                    </div>
                                    <div class="col-6">
                                        <label for="exampleInputEmail1">Breve descrição da página</label>
                                            <textarea v-model="site_description" name="site_description" class="form-control" rows="3"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card de dados do endereço -->
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Endereço</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-3">
                                        <label for="exampleInputEmail1">Cidade</label>
                                        <select v-model="fk_id_city" name="fk_id_city" class="custom-select">
                                            <option v-for="(city, index) in cities" :key="index" :value="city.id">{{ city.name_city }}</option>
                                        </select>
                                    </div>
                                    <div class="col-3">
                                        <label for="exampleInputPassword1">Bairro</label>
                                        <select v-model="fk_id_district" name="fk_id_district" class="custom-select">
                                            <option v-for="(district, index) in districts" :key="index" :value="district.id">{{ district.name_district }}</option>
                                        </select>
                                    </div>
                                    <div class="col-6">
                                        <label for="exampleInputEmail1">Endereço</label>
                                        <Field v-model="address" class="form-control" name="address"
                                            type="text" />
                                    </div>
                                </div>
                                <p></p>
                            </div>
                        </div>
                        <!-- Card de descrição do site -->
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Descrição</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <label for="exampleInputEmail1">Descrição completa do painel</label>
                                        <textarea v-model="description" name="description" class="form-control" rows="3"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card de demais Informações -->
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Demais Informações</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6">
                                        <label for="exampleInputEmail1">Link externo do painel</label>
                                        <Field v-model="link_external" class="form-control" name="link_external"
                                            type="text" />
                                    </div>
                                    <div class="col-6">
                                        <label for="exampleInputEmail1">Link de vídeo do painel</label>
                                        <Field v-model="link_video" class="form-control" name="link_video" type="text" />
                                    </div>
                                </div>
                                <p></p>
                                <div class="row">
                                    <div class="col-12">
                                        <label for="exampleInputEmail1">Mapa do google</label>
                                        <textarea v-model="map" name="map" class="form-control" rows="3"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button :disabled="spinner.loading_button" type="submit" class="btn btn-success">CRIAR NOVO
                                    PAINEL<img v-if="spinner.loading_button" height="20" width="20"
                                        src="@/assets/img/spinner.svg" alt="" class="w-5 h-5 mr-2">
                                </button>
                            &nbsp;
                            <RouterLink :to="{ name: 'panels' }" class="btn btn-warning">CANCELAR E
                                VOLTAR</RouterLink>
                                <p></p>
                        </div>
                    </div>
                    <!-- Fim da coluna 12 -->
                </div>
            </Form>
            <!-- Fim da linha geral e do FORM-->
        </div>
        <!-- Fim do container -->
    </section>
    <!-- Fim do content -->
</template>

<script>
import Cookie from '@/service/cookie';
import api from '@/plugins/api'
import * as yup from 'yup'
import { Form, Field, ErrorMessage } from 'vee-validate';
import messages from '@/utils/messages';
import Swal from 'sweetalert2';

export default {
    name: 'AddPanelView',

    components: {
        Field,
        Form,
    },

    data() {
        const schema = yup.object({
            name: yup.string().required(),
        });
        return {
            schema,
            categories: {},
            cities: {},
            districts: {},
            internal_code: '',
            name: '',
            fk_id_categoria: '',
            fk_id_city: '',
            fk_id_district: '',
            detach: '',
            active_sale: '',
            active_site: '',
            site_url: '',
            site_title: '',
            site_description: '',
            site_keyword: '',
            address: '',
            observation: '',
            description: '',
            link_external: '',
            link_video: '',
            map: '',
            response: {
                color: '',
                message: '',
            },
            spinner: {
                loading_button: false,
            },
            token: Cookie.getToken(),
        };
    },

    watch: {
        fk_id_city(value) {
            this.getDistrictsByCity();
        }
    },

    mounted () {
        api.get('v1/categories/',{ headers: {'Authorization': this.token }}).then(response => {
            this.categories = response.data;
        });
        api.get('v1/cities/',{ headers: {'Authorization': this.token }}).then(response => {
            this.cities = response.data;
        });
        api.get('v1/districts/',{ headers: {'Authorization': this.token }}).then(response => {
            this.districts = response.data;
        });
    },

    methods: {
        async add() {
            const validator = await this.$refs.addForm.validate();
            if (!validator) { return; }

            const payload = {
                name: this.name,
                internal_code: this.internal_code,
                fk_id_categoria: this.fk_id_categoria,
                fk_id_city: this.fk_id_city,
                fk_id_district: this.fk_id_district,
                detach: this.detach,
                active_sale: this.active_sale,
                active_site: this.active_site,
                site_url: this.site_url,
                site_title: this.site_title,
                site_description: this.site_description,
                site_keyword: this.site_keyword,
                address: this.address,
                observation: this.observation,
                description: this.description,
                link_external: this.link_external,
                link_video: this.link_video,
                map: this.map,
            };

            this.resetResponse();

            this.spinner.loading_button = true;
            api.post('v1/panels', payload, { headers: { 'Authorization': this.token } }).then((response) => {
                Swal.fire(
                    'Ótimo trabalho!',
                    this.response.message = 'Seus dados foram inseridos com sucesso!',
                    'success'
                );
                this.$router.back()
            }).catch((e) => {
                const errorCode = e?.response?.data?.error || 'ServerError';
                Swal.fire(
                    'Deu erro, hein!',
                    this.response.message = messages[errorCode],
                    'error'
                );
            }).finally(() => {
                this.spinner.loading_button = false;
            });
        },

        getDistrictsByCity() {
            api.get('v1/districts/getDistrictsByCity?id=' + this.fk_id_city,{ headers: {'Authorization': this.token }}).then(response => {
            this.districts = response.data;
            console.log(this.districts);
        });
        },

        resetResponse() {
            this.response.color = '';
            this.response.message = '';
        },
        
    },
    
}
</script>

