<template>
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Exibindo todos os Painéis</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><RouterLink :to="{ name: 'index' }">Início</RouterLink></li>
                        <li class="breadcrumb-item active">Exibindo todos os Painéis</li>
                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">

        <!-- Default box -->
        <div class="card">
            <div class="card-header">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <RouterLink :to="{ name: 'addPanel' }" class="btn btn-primary btn-block">Adicionar novo
                        </RouterLink>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <select v-model="category_id" class="form-control col-md-3">
                    <option value=""> -- Escolha a categoria --</option>
                    <option v-for="(category, index) in categories" :key="index" :value="category.id">{{ category.name }}</option>
                </select>

                <table class="table">
                    <thead>
                        <tr>
                            <th class="text-center">
                                Título
                            </th>
                            <th class="text-center">
                                Ativo no site?
                            </th>
                            <th class="text-center">
                                Destaque no site?
                            </th>
                            <th class="text-center">
                                Código
                            </th>
                            <th class="text-center">
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(panel, index) in panels" :key="index">
                            <td class="text-center">{{ panel.name }}</td>
                            <td class="text-center">
                                <span v-if="panel.active_site == 1" class="badge bg-success">SIM</span>
                                <span v-else class="badge bg-danger">NÃO</span>
                            </td>
                            <td class="text-center">
                                <span v-if="panel.detach == 1" class="badge bg-success">SIM</span>
                                <span v-else class="badge bg-danger">NÃO</span>
                            </td>
                            <td class="text-center">{{ panel.internal_code }}</td>
                            <td class="text-center">
                                    <button type="button" class="btn btn-warning dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                    Imagens
                                    </button>
                                    <ul class="dropdown-menu" style="">
                                        <li class="dropdown-item">
                                            <RouterLink :to="{ path: '/paineis/capa/' + panel.id }">Capa
                                        </RouterLink>   
                                        </li>
                                        <li class="dropdown-item">
                                            <RouterLink :to="{ path: '/paineis/capa/' + panel.id }">Galeria
                                        </RouterLink>   
                                        </li>
                                    </ul>
                                    &nbsp;
                                <RouterLink :to="{ path: '/paineis/editar/' + panel.id }" class="btn btn-default">Editar
                                    </RouterLink>
                                &nbsp;
                                <button type="button" @click="deletePanel(panel.id)" class="btn btn-danger">Excluir
                                    </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
        <!-- /.card -->

    </section>
    <!-- /.content -->
</template>

<script>

import api from '@/plugins/api'
import Cookie from '@/service/cookie';
import Swal from 'sweetalert2';

export default {
    name: 'PanelView',
    data() {
        return {
            category_id: '', 
            panelId: '',
            categories: {},
            panels: {},
            token: Cookie.getToken(),
        };
    },
    watch: {
        category_id(value) {
            this.getResults();
        }
    },

    mounted () {
        api.get('v1/categories',{ headers: {'Authorization': this.token }}).then(response => {
            this.categories = response.data;
        });
        this.getResults();
    },

    methods: { 
        getResults() {
            api.get('v1/panels/getPanels?id=' + this.category_id,{ headers: {'Authorization': this.token }}).then(response => {
            this.panels = response.data;
        });
        },

        deletePanel(panelId) {
            Swal.fire({
                title: 'Você quer excluir esse registro?',
                text: "Você não poderá reverter isso!",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Pensei melhor, não quero!',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, quero deletar!'
            }).then((result) => {
                if (result.isConfirmed) {
                    const token = Cookie.getToken();
                    api.delete(`v1/panels/${panelId}`, { headers: { 'Authorization': token } }).then(response => {
                        Swal.fire(
                        'Registro excluído!',
                        'O registro selecionado foi excluído.',
                        'success'
                    ),
                    this.getResults();
                });   
                }
            })
        }
    },
}
</script>

<style></style>