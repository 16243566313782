export default{

    namespaced: true,

    state: {
        user: {},
    },
    mutations: {
        STORE_USER(state, user){
            //Aqui eu digo que o state user ali em cima recebe o user
            state.user = user;
        },
    },
    actions: {
    },
    modules: {
    }
  }