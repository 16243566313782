<template>
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Perfil</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <RouterLink :to="{ name: 'index' }">Início</RouterLink>
                        </li>
                        <li class="breadcrumb-item active">Perfil do usuário</li>
                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3">

                    <!-- Profile Image -->
                    <div class="card card-primary card-outline">
                        <div class="card-body box-profile">
                            <div class="text-center">
                                <img class="profile-user-img img-fluid img-circle" src="dist/img/user2-160x160.jpg"
                                    alt="User profile picture">
                            </div>

                            <h3 class="profile-username text-center">{{ user.first_name }} {{ user.last_name }}</h3>

                            <p class="text-muted text-center">Perfil de acesso</p>
                            <!--
                                    <ul class="list-group list-group-unbordered mb-3">
                                        <li class="list-group-item">
                                            <b>Followers</b> <a class="float-right">1,322</a>
                                        </li>
                                    </ul>
                                -->
                            <RouterLink :to="{ name: 'index' }" class="btn btn-primary btn-block">Voltar ao início
                            </RouterLink>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
                <!-- /.col -->

                <div class="col-md-9">
                    <div class="card">
                        <div class="card-header p-2">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#settings"
                                        data-toggle="tab">Informações</a></li>
                            </ul>
                        </div><!-- /.card-header -->

                        <div class="card-body">
                            <div class="tab-content">
                                <div class="active tab-pane" id="settings">
                                    <Form @submit="update" :validation-schema="schema" v-slot="{ errors }" ref="updateForm">
                                        <span class="p-error">{{ errors.firstName }}</span>
                                        <div class="form-group row">
                                            <label for="inputName" class="col-sm-2 col-form-label">Nome</label>
                                            <div class="col-sm-10">
                                                <Field v-model="firstName" class="form-control" name="firstName" type="text"
                                                    :class="{ 'p-invalid': errors.name }" />
                                            </div>
                                        </div>
                                        <span class="p-error">{{ errors.lastName }}</span>
                                        <div class="form-group row">
                                            <label for="inputName" class="col-sm-2 col-form-label">Sobrenome</label>
                                            <div class="col-sm-10">
                                                <Field v-model="lastName" class="form-control" name="lastName" type="text"
                                                    :class="{ 'p-invalid': errors.name }" />
                                            </div>
                                        </div>
                                        <span class="p-error">{{ errors.email }}</span>
                                        <div class="form-group row">
                                            <label for="inputEmail" class="col-sm-2 col-form-label">E-mail</label>
                                            <div class="col-sm-10">
                                                <Field v-model="email" class="form-control" name="email" type="email"
                                                    :class="{ 'p-invalid': errors.name }" />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="inputName2" class="col-sm-2 col-form-label">Senha</label>
                                            <div class="col-sm-10">
                                                <Field v-model="password" class="form-control" name="password"
                                                    type="password"/>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="offset-sm-2 col-sm-10">
                                                <button :disabled="spinner.update_user" type="submit"
                                                    class="btn btn-success">ALTERAR <img v-if="spinner.update_user"
                                                        height="20" width="20" src="@/assets/img/spinner.svg" alt=""
                                                        class="w-5 h-5 mr-2">
                                                </button>
                                            </div>
                                            <div v-if="response.message"
                                                :class="`rounded-sm bg-${response.color}-100 p-4 mb-4`">
                                                <h3 :class="`text-sm leading-5 font-medium text-${response.color}-800`">
                                                    {{ response.message }}
                                                </h3>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                <!-- /.tab-pane -->
                            </div>
                            <!-- /.tab-content -->
                        </div><!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
</template>
  
<script>
import { mapState, mapMutations } from 'vuex';
import apime from '@/plugins/api-internal'
import * as yup from 'yup'
import { Form, Field, ErrorMessage } from 'vee-validate';
import messages from '@/utils/messages';
import Swal from 'sweetalert2';
export default {
    name: 'ProfileView',

    components: {
        Field,
        Form,
    },

    data() {
        const schema = yup.object({
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            email: yup.string().email().required(),
            //password: yup.string().required(),
        });
        return {
            schema,
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            response: {
                color: '',
                message: '',
            },
            spinner: {
                update_user: false,
            },
        };
    },
    //Para utilizar o mapState, precisamos importar o vuex e MapMutations.
    computed: {
        ...mapState({
            user: state => state.user.user,
        }),
    },

    created() {
        //Sem o mapeamento acima, cada campo o mapamento ficaria assim: this.$store.state.user.user.first_name ...
        // Com o mapeamento fica: v-model(this.first_name) = state(this.user.first_name)
        this.firstName = this.user.first_name;
        this.lastName = this.user.last_name;
        this.email = this.user.email;
    },

    methods: {
        ...mapMutations('user', ['STORE_USER']),

        async update() {
            const validator = await this.$refs.updateForm.validate();
            if (!validator) { return; }

            const payload = {
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.email,
            };

            if (this.password) {
                payload.password = this.password;
            }

            this.resetResponse();

            this.spinner.update_user = true;
            apime.put('v1/me', payload).then((response) => {
                Swal.fire(
                'Ótimo trabalho!',
                this.response.message = 'Seus dados foram atualizados com sucesso!',
                'success'
                )
                this.STORE_USER(response.data.data);
                this.$router.push('/') 
            }).catch((e) => {
                const errorCode = e?.response?.data?.error || 'ServerError';
                this.response.color = 'red';
                this.response.message = messages[errorCode];
            }).finally(() => {
                this.spinner.update_user = false;
            });
        },
        resetResponse() {
            this.response.color = '';
            this.response.message = '';
        },
    },


}
</script>
  