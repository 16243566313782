<template>
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Relatório geral de painéis</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <RouterLink :to="{ name: 'index' }">Início</RouterLink>
                        </li>
                        <li class="breadcrumb-item active">Relatório geral de painéis</li>
                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">

        <!-- Default box -->
        <div class="card">
            <div class="card-header">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <button class="btn btn-primary btn-block" onclick="window.print()">Imprimir / Salvar PDF</button>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-2">
                        <select v-model="category_id" name="category_id" class="custom-select">
                            <option value=""> -- Categoria --</option>
                            <option v-for="(category, index) in categories" :key="index" :value="category.id">{{
                                category.name }}</option>
                        </select>
                    </div>
                    <div class="col-3">
                        <select v-model="fk_id_city" name="fk_id_city" class="custom-select">
                            <option value="" disabled selected>Selecione a cidade</option>
                            <option v-for="(city, index) in cities" :key="index" :value="city.id">{{ city.name_city }}
                            </option>
                        </select>
                    </div>
                    <div class="col-3">
                        <select v-model="fk_id_district" name="fk_id_district" class="custom-select">
                            <option value="" disabled selected>Selecione o bairro</option>
                            <option v-for="(district, index) in districts" :key="index" :value="district.id">{{
                                district.name_district }}</option>
                        </select>
                    </div>
                    <div class="col-2">
                        <input type="text" class="form-control" placeholder="Código do painel">
                    </div>
                    <div class="col-2">
                        <button @click.prevent="getResults()" class="btn btn-success btn-block">BUSCAR</button>
                    </div>

                </div>
                <p>&nbsp;</p>
                

            </div>
        </div>

        <div class="row">
            <div v-for="(panel, index) in panels" :key="index" class="col-lg-6">
                    <div class="card card-widget">
                        <div class="card-header">
                            <div  class="user-block">
                                <span style="margin-left: 0px;" class="username"><a href="#">{{ panel.name }}</a></span>
                                <span style="margin-left: 0px;" class="description">CÓDIGO INTERNO - {{ panel.internal_code }}</span>
                            </div>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="card-body">
                            <img class="img-fluid pad" height="300" :src="sourceImg+panel.image_cover" alt="Photo">
                            <p></p>
                        <button type="button" class="btn btn-default btn-sm"><i class="fas fa-share"></i> DETALHES DO
                            PAINEL</button>
                            <span class="float-right text-muted">Categoria {{ panel.name_category }}</span>
                        </div>
                        <div class="card-footer card-comments">
                            <div class="card-comment">
                                <div style="margin-left: 0px;" class="comment-text">
                                    <span class="username">
                                        <i class="fas fa-home"></i> ENDEREÇO:
                                        <span class="comment-text float-right">{{panel.name_city}} - {{panel.name_district}}</span>
                                    </span>
                                    {{panel.address}}
                                </div>
                                <!-- /.comment-text -->
                            </div>
                            <div class="card-comment">
                                <div style="margin-left: 0px;" class="comment-text">
                                    <span class="username">
                                        <i class="fas fa-edit"></i> DESCRIÇÃO:
                                    </span>
                                    {{ panel.description }}
                                </div>
                            </div>
                            <div class="card-comment">
                                <span class="username">
                                        <i class="fas fa-map"></i> MAPA:
                                    </span>
                                    <div v-html="panel.map"> </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
        </div>

    </section>
    <!-- /.content -->
</template>

<script>

import api from '@/plugins/api'
import Cookie from '@/service/cookie';
import Swal from 'sweetalert2';

export default {
    name: 'ReportGenericPanelView',
    data() {
        return {
            category_id: '',
            panels: {},
            categories: {},
            cities: {},
            districts: {},
            fk_id_categoria: '',
            fk_id_city: '',
            fk_id_district: '',
            token: Cookie.getToken(),
        };
    },
    watch: {
        fk_id_city(value) {
            this.getDistrictsByCity();
        }
    },

    mounted() {
        api.get('v1/categories', { headers: { 'Authorization': this.token } }).then(response => {
            this.categories = response.data;
        });
        api.get('v1/cities', { headers: { 'Authorization': this.token } }).then(response => {
            this.cities = response.data;
        });
        api.get('v1/districts', { headers: { 'Authorization': this.token } }).then(response => {
            this.districts = response.data;
        });
    },

    methods: {

        getDistrictsByCity() {
            api.get('v1/districts/getDistrictsByCity?id=' + this.fk_id_city, { headers: { 'Authorization': this.token } }).then(response => {
                this.districts = response.data;
                console.log(this.districts);
            });
        },

        getResults() {
            api.get('v1/panels/getPanelsReport?ca=' + this.category_id + '&ci=' + this.fk_id_city + '&di=' + this.fk_id_district, { headers: { 'Authorization': this.token } }).then(response => {
                this.panels = response.data;
            });
        },
    },
}
</script>

<style></style>