import { createRouter, createWebHistory } from 'vue-router'
import LayoutAuth from '@/layouts/Auth.vue'
import LayoutDefault from '@/layouts/Default.vue'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue';
import Home from '@/views/Home.vue'
import Guard from '@/service/middleware';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import Profile from '@/views/Profile.vue';
//Imports do módulo de Categorias
import ShowCategories from '@/views/Categories/ShowCategories.vue'
import AddCategory from '@/views/Categories/AddCategory.vue'
import EditCategory from '@/views/Categories/EditCategory.vue'
//Imports do módulo de Marcas
import ShowBrands from '@/views/Brands/ShowBrands.vue'
import AddBrand from '@/views/Brands/AddBrand.vue'
import EditBrand from '@/views/Brands/EditBrand.vue'
import UploadImageBrand from '@/views/Brands/UploadImageBrand.vue'
//Imports do módulo de Painéis
import ShowPanels from '@/views/Panels/ShowPanels.vue'
import ReportGeneric from '@/views/Panels/ReportGeneric.vue'
import addPanel from '@/views/Panels/AddPanel.vue'
import EditPanel from '@/views/Panels/EditPanel.vue'
import UploadCoverPanel from '@/views/Panels/UploadCover.vue'


const routes = [

  {
    path: '/', component: LayoutDefault,
    beforeEnter: Guard.redirectIfNotAuthenticated,
    children: [
      { path: '', name: 'index', component: Home },
      { path: 'perfil', name: 'profile', component: Profile },
    ],
  },
  {
    path: '/categorias', component: LayoutDefault,
    beforeEnter: Guard.redirectIfNotAuthenticated,
    children: [
      { path: '', name: 'categories', component: ShowCategories },
      { path: 'nova', name: 'addCategory', component: AddCategory },
      { path: 'editar/:id', name: 'editCategory', component: EditCategory },
    ],
  },
  {
    path: '/marcas', component: LayoutDefault,
    beforeEnter: Guard.redirectIfNotAuthenticated,
    children: [
      { path: '', name: 'brands', component: ShowBrands },
      { path: 'nova', name: 'addBrand', component: AddBrand },
      { path: 'editar/:id', name: 'editBrand', component: EditBrand },
      { path: 'logo/:id', name: 'uploadImageBrand', component: UploadImageBrand },
    ],
  },
  {
    path: '/paineis', component: LayoutDefault,
    beforeEnter: Guard.redirectIfNotAuthenticated,
    children: [
      { path: '', name: 'panels', component: ShowPanels },
      { path: 'relatorio-geral', name: 'reportGeneric', component: ReportGeneric },
      { path: 'novo', name: 'addPanel', component: addPanel },
      { path: 'editar/:id', name: 'editPanel', component: EditPanel },
      { path: 'capa/:id', name: 'uploadCoverPanel', component: UploadCoverPanel },
    ],
  },
  {
    path: '/login', component: LayoutAuth,
    beforeEnter: Guard.redirectIfAuthenticated,
    children: [
      { path: '', name: 'login', component: Login },
    ],
  },
  {
    path: '/esqueci-senha', component: LayoutAuth,
    children: [
      { path: '', name: 'forgotPassword', component: ForgotPassword },
    ],
  },
  {
    path: '/logout', component: LayoutAuth,
    children: [
      { path: '', name: 'logout', component: Logout },
    ],
  },
  {
    path: '/recuperar-senha', component: LayoutAuth,
    children: [
      { path: '', name: 'resetPassword', component: ResetPassword },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
