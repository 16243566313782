export default{

    ServerError: 'Ops! Alguma coisa deu errado.',
    LoginInvalidException: 'Ops! E-mail e/ou senha inválidos.',
    UserHasBeenTakenException: 'Ops! Parece que este usuário já existe.',
    VerifyEmailTokenInvalidException: 'Ops! Parece que este token está inválido.',
    UserNotFoundException: 'Ops! Parece que este usuário não existe.',
    ResetPasswordTokenInvalidException: 'Ops! Parece que este token está inválido.',
    ForgotEmaiInvalidException: 'O E-mail não foi encontrado em nossa base de dados!',
    ImageFormatInvalidException: 'O formato de imagem que você quer fazer upload é inválido!',
    UploadImageException: 'Não foi possível fazer o upload da imagem!',

}