<template>
    <div class="hold-transition sidebar-mini layout-fixed">
      <div class="wrapper">
            <TheHeader/>
            <SideBar/>
            <div class="content-wrapper">
              <RouterView />
            </div>
            <TheFooter/>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import TheHeader from '@/components/Partials/TheHeader.vue'
import SideBar from '@/components/Partials/Sidebar.vue'
import TheFooter from '@/components/Partials/TheFooter.vue'

export default {
  name: 'LayoutDefault',
  components: {
    TheHeader,
    SideBar,
    TheFooter,
  }
}
</script>
