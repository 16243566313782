
import Vue from 'vue';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



const app = createApp(App)

//app.config.globalProperties.sourceImg = 'http://localhost:8000/';
app.config.globalProperties.sourceImg = 'https://api.linearpaineis.com.br/';

app.use(store).use(router).mount('#app')

