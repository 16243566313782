<template>
    <div class="hold-transition login-page">
        <div class="login-box">
            <RouterView />
        </div>
    </div>
</template>

<script>
export default {
    name: 'LayoutAuth',

    data() {
        return {
        };
    },

    methods: {},
};
</script>
