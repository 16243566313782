<template>
    <div>
        <footer class="main-footer">
            <strong>Copyright &copy; 2023 <a target="_BLANK" href="https://ansmarketing.com.br">ANS Marketing</a>.</strong>
            Todos os direitos reservados.
            <div class="float-right d-none d-sm-inline-block">
                <b>Versão</b> 1.0.0
            </div>
        </footer>
    </div>
    <div>
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
    </div>
</template>
  
<script>
export default {
    name: 'TheFooter',
    props: {
        msg: String
    }
}
</script>