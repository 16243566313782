<template>
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Adicionar imagem</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <RouterLink :to="{ name: 'index' }">Início</RouterLink>
                        </li>
                        <li class="breadcrumb-item active">Adicionar imagem</li>
                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">
                            <div class="tab-content">
                                <div class="active tab-pane" id="settings">
                                    <div class="form-group row">
                                        <img width="300" height="300" :src="imgPreview" />
                                        <p>&nbsp;</p>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-10">
                                            <input @change="newFile" type="file" name="cover" ref="featured_image">
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button :disabled="spinner.loading_button" @click="upload"
                                            class="btn btn-success">FAZER UPLOAD DE IMAGEM<img v-if="spinner.loading_button"
                                                height="20" width="20" src="@/assets/img/spinner.svg" alt=""
                                                class="w-5 h-5 mr-2">
                                        </button>
                                        &nbsp;
                                        <RouterLink :to="{ name: 'brands' }" class="btn btn-warning">CANCELAR E
                                            VOLTAR</RouterLink>
                                        <p></p>
                                    </div>
                                </div>
                                <!-- /.tab-pane -->
                            </div>
                            <!-- /.tab-content -->
                        </div><!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
</template>

<script>
import Cookie from '@/service/cookie';
import api from '@/plugins/api'
import messages from '@/utils/messages';
import Swal from 'sweetalert2';

export default {
    name: 'UploadImage',

    data() {
        return {
            myVar: this.sourceImg,
            brandId: this.$route.params.id,
            cover: '',
            image: '',
            imageDefault: 'http://via.placeholder.com/300',
            file: '',
            response: {
                color: '',
                message: '',
            },
            spinner: {
                add_cover: false,
            },
            token: Cookie.getToken(),
        };
    },

    mounted() {
        //console.log(this.$route.params.id);
        this.getBrandEdit(this.brandId);
    },

    computed:
    {
        imgPreview() {
            return this.image == "" ? this.imageDefault : this.image;
        }
    },

    methods: {

        newFile(event) {
            this.file = event.target.files[0]
            this.image = URL.createObjectURL(this.file);
        },

        getBrandEdit(brandId) {
            const token = Cookie.getToken();
            api.get(`v1/brands/${brandId}`, { headers: { 'Authorization': token } }).then(response => {
                this.image = response.data.thumbnail;
                if(this.image){
                    this.image = this.myVar + response.data.thumbnail;
                }
                console.log(response.data)
            });
        },

        upload() {
            var form = '';
            form = new FormData();
            form.append('imagem', this.file);
            form.append('name', this.file.name);


            api.post(`v1/brands/${this.brandId}/uploadImage`, form, { headers: { 'Authorization': this.token, 'Content-Type': 'multipart/form-data' } }).then((response) => {
                Swal.fire(
                    'Ótimo trabalho!',
                    this.response.message = 'O upload da sua imagem foi realizada com sucesso!',
                    'success'
                );
                this.$router.back()
            }).catch((e) => {
                const errorCode = e?.response?.data?.error || 'ServerError';
                Swal.fire(
                    'Deu erro, hein!',
                    this.response.message = messages[errorCode],
                    'error'
                );
            }).finally(() => {
                this.spinner.loading_button = false;
            });

        },

        resetResponse() {
            this.response.color = '';
            this.response.message = '';
        },
    },


}
</script>