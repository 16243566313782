<template>
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Adicionar nova categoria</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <RouterLink :to="{ name: 'index' }">Início</RouterLink>
                        </li>
                        <li class="breadcrumb-item active">Adicionar nova categoria</li>
                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
            <div class="row">

                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">
                            <div class="tab-content">
                                <div class="active tab-pane" id="settings">
                                    <Form @submit="add" :validation-schema="schema" v-slot="{ errors }" ref="addForm">
                                        <span class="p-error">{{ errors.name }}</span>
                                        <div class="form-group row">
                                            <label for="inputName" class="col-sm-2 col-form-label">Nome da categoria</label>
                                            <div class="col-sm-10">
                                                <Field v-model="name" class="form-control" name="name" type="text"
                                                    :class="{ 'p-invalid': errors.name }" />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="offset-sm-2 col-sm-10">
                                                <button :disabled="spinner.loading_button" type="submit"
                                                    class="btn btn-success">CRIAR NOVA CATEGORIA<img v-if="spinner.loading_button"
                                                        height="20" width="20" src="@/assets/img/spinner.svg" alt=""
                                                        class="w-5 h-5 mr-2">
                                                </button>
                                                &nbsp;
                                                <RouterLink :to="{ name: 'categories' }" class="btn btn-warning" >CANCELAR E VOLTAR</RouterLink>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                <!-- /.tab-pane -->
                            </div>
                            <!-- /.tab-content -->
                        </div><!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
</template>

<script>
import Cookie from '@/service/cookie';
import api from '@/plugins/api'
import * as yup from 'yup'
import { Form, Field, ErrorMessage } from 'vee-validate';
import messages from '@/utils/messages';
import Swal from 'sweetalert2';

export default {
    name: 'AddCategoryView',

    components: {
        Field,
        Form,
    },

    data() {
        const schema = yup.object({
            name: yup.string().required(),
        });
        return {
            schema,
            name: '',
            response: {
                color: '',
                message: '',
            },
            spinner: {
                loading_button: false,
            },
            token: Cookie.getToken(),
        };
    },

    methods: {
        async add() {
            const validator = await this.$refs.addForm.validate();
            if (!validator) { return; }

            const payload = {
                name: this.name,
            };

            this.resetResponse();

            this.spinner.loading_button = true;
            api.post('v1/categories', payload,{ headers: {'Authorization': this.token }}).then((response) => {
                Swal.fire(
                'Ótimo trabalho!',
                this.response.message = 'Seus dados foram inseridos com sucesso!',
                'success'
                );
                this.$router.back()                 
            }).catch((e) => {
                const errorCode = e?.response?.data?.error || 'ServerError';
                Swal.fire(
                'Deu erro, hein!',
                this.response.message = messages[errorCode],
                'error'
                );
            }).finally(() => {
                this.spinner.loading_button = false;
            });
        },
        resetResponse() {
            this.response.color = '';
            this.response.message = '';
        },
    },


}
</script>