<template>
    <div>
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">

                <center>
                    <span class="brand-text font-weight-light">SISTEMA</span>
                </center>
            </a>

            <!-- Sidebar -->
            <div class="sidebar">
                <!-- Sidebar user panel (optional) -->
                <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div class="info">
                        <RouterLink :to="{ name: 'profile' }" class="d-block">{{user.first_name }} {{ user.last_name }}</RouterLink>
                    </div>
                </div>

                <!-- Sidebar Menu -->
                <nav class="mt-2">
                    
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li class="nav-header">SITE</li>
                        <li class="nav-item">
                            <RouterLink  class="nav-link" :to="{ name: 'index' }">
                                <i class="nav-icon fas fa-columns"></i>
                                <p>
                                    Início
                                </p>
                            </RouterLink>
                        </li>
                        <li class="nav-item">
                            <RouterLink :to="{ name: 'categories' }" class="nav-link">
                                <i class="nav-icon far fa-image"></i>
                                <p>
                                    Categorias
                                </p>
                            </RouterLink>
                        </li>
                        <li class="nav-item">
                            <RouterLink :to="{ name: 'brands' }" class="nav-link">
                                <i class="nav-icon far fa-image"></i>
                                <p>
                                    Marcas
                                </p>
                            </RouterLink>
                        </li>
                        <li class="nav-item">
                            <RouterLink :to="{ name: 'panels' }" class="nav-link">
                                <i class="nav-icon far fa-image"></i>
                                <p>
                                    Painéis
                                </p>
                            </RouterLink>
                        </li>
                        <li class="nav-header">SISTEMA</li>
                        <li class="nav-item">
                            <RouterLink :to="{ name: 'reportGeneric' }" class="nav-link">
                                <i class="nav-icon far fa-image"></i>
                                <p>
                                    Relatório Geral
                                </p>
                            </RouterLink>
                        </li>
                    </ul>
                </nav>
                <!-- /.sidebar-menu -->
            </div>
            <!-- /.sidebar -->
        </aside>
    </div>
</template>
  
<script>
import { mapState } from 'vuex';
export default {
    name: 'SideBar',
    
    computed: {
    ...mapState({
      user: state => state.user.user,
    }), 
  },

}
</script>