<template>
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Marcas</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <RouterLink :to="{ name: 'index' }">Início</RouterLink>
                        </li>
                        <li class="breadcrumb-item active">Marcas</li>
                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">

        <!-- Default box -->
        <div class="card">
            <div class="card-header">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <RouterLink :to="{ name: 'addBrand' }" class="btn btn-primary btn-block">Adicionar nova
                        </RouterLink>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="text-center">
                                Logo
                            </th>
                            <th class="text-center">
                                Nome da marca
                            </th>
                            <th class="text-center">
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(brand, index) in brands" :key="index" :value="brand.id">
                            <td class="text-center" v-if="!brand.thumbnail" ><RouterLink :to="{ path: '/marcas/logo/' + brand.id }"><img width="100" height="100" :src="this.imageDefault" /></RouterLink>  </td>
                            <td class="text-center" v-else><RouterLink :to="{ path: '/marcas/logo/' + brand.id }"><img width="100" height="100" :src="myVar + brand.thumbnail" /></RouterLink></td>
                            <td class="text-center">{{ brand.name }}</td>
                            <td class="text-center">
                                <RouterLink :to="{ path: '/marcas/editar/' + brand.id }" class="btn btn-default">Editar
                                    marca</RouterLink>
                                &nbsp;
                                <button type="button" @click="deleteBrand(brand.id)" class="btn btn-default">Excluir
                                    marca</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- /.card-body -->
            <!-- /.card-footer-->
        </div>
        <!-- /.card -->

    </section>
    <!-- /.content -->
</template>

<script>

import api from '@/plugins/api'
import Cookie from '@/service/cookie';
import Swal from 'sweetalert2';

export default {
    name: 'BrandView',

    data() {
        return {
            myVar: this.sourceImg,
            imageDefault: 'http://via.placeholder.com/100',
            brand_id: '',
            brands: {},

        };
    },

    mounted() {
        this.getBrands();
    },


    methods: {
        getBrands() {
            const token = Cookie.getToken();
            api.get('v1/brands', { headers: { 'Authorization': token } }).then(response => {
                this.brands = response.data;
            });
        },

        deleteBrand(brandId) {
            Swal.fire({
                title: 'Você quer excluir esse registro?',
                text: "Você não poderá reverter isso!",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Pensei melhor, não quero!',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, quero deletar!'
            }).then((result) => {
                if (result.isConfirmed) {
                    const token = Cookie.getToken();
                    api.delete(`v1/brands/${brandId}`, { headers: { 'Authorization': token } }).then(response => {
                        Swal.fire(
                        'Registro excluído!',
                        'O registro selecionado foi excluído.',
                        'success'
                    ),
                    this.getBrands();
                });   
                }
            })
        }
    },
}
</script>

<style></style>