import axios from 'axios'
import Cookie from '@/service/cookie';

const token = Cookie.getToken();

const apime = axios.create({
    baseURL: 'https://api.linearpaineis.com.br/api',
    headers: { 'content-type': 'application/json', 'Accept': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': token  },
});


export default apime;