<template>
    <div v-if="response.message" :class="`rounded-sm bg-${response.color}-100 p-4 mb-4`">
            <h3 :class="`text-sm leading-5 font-medium text-${response.color}-800`">
                {{ response.message }}
            </h3>
        </div>
    <div class="card">
        <div class="card-body login-card-body">
            <p class="login-box-msg">Você esqueceu sua senha? Aqui você pode facilmente recuperar uma nova senha.</p>
            <Form @submit="forgotPassword" :validation-schema="schema" v-slot="{ errors }" ref="forgotPasswordForm">
                <span class="p-error">{{ errors.email }}</span>
                <div class="input-group mb-3">
                    <Field v-model="email" class="form-control" name="email" type="email"
                        :class="{ 'p-invalid': errors.name }" />
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button :disabled="spinner.forgot_password" type="submit" class="btn btn-primary btn-block">RECUPERAR SENHA <img
                                    v-if="spinner.forgot_password" height="20" width="20" src="@/assets/img/spinner.svg" alt=""
                                    class="w-5 h-5 mr-2">
                            </button>
                    </div>
                    <!-- /.col -->
                </div>
            </Form>

            <p class="mt-3 mb-1">
                <RouterLink :to="{ name: 'login' }" class="text-sm font-light">
                    Voltar ao login
                </RouterLink>
            </p>
        </div>
        <!-- /.login-card-body -->
    </div>
</template>

<script>
import api from '@/plugins/api'
import * as yup from 'yup'
import { Form, Field, ErrorMessage } from 'vee-validate';
import messages from '../utils/messages';

export default {
    name: 'LayoutForgotPassword',

    components: {
        Field,
        Form,
    },

    data() {
        const schema = yup.object({
            email: yup.string().email().required(),
        });

        return {
            schema,
            email: '',
            response: {
                color: '',
                message: '',
            },
            spinner: {
                forgot_password: false,
            },
        };
    },

    methods: {
        async forgotPassword() {
            const validator = await this.$refs.forgotPasswordForm.validate();
            if (!validator) { return; }

            this.resetResponse();

            const payload = {
                email: this.email,
            };

            this.spinner.forgot_password = true;

            api.post('v1/forgot-password', payload).then(() => {
                this.response.color = 'green';
                this.response.message = 'Enviamos um e-mail com instruções de recuperação de senha.';
                //this.resetForm();
            }).catch((e) => {
                const errorCode = e?.response?.data?.error || 'ServerError';
                this.response.color = 'red';
                this.response.message = messages[errorCode];
            }).finally(() => {
                this.spinner.forgot_password = false;
            });
        },

        resetResponse() {
            this.response.color = '';
            this.response.message = '';
        },

        resetForm() {
            this.$refs.form.reset();
            this.email = '';
        },
    },
};
</script>
